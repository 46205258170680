function NavbarProfilerController(Auth, $scope, $location, $window, $log) {
  this.userName = Auth.user.name;

  this.loggedIn = Auth.isLoggedIn;

  // Implicit return, sor reads () => {return Auth.isLoggedIn() && !Auth.isGuest()}
  this.showUserControls = () => Auth.isLoggedIn() && !Auth.isGuest();

  $scope.$watchCollection(() => Auth.user, (newValue) => {
    this.userName = newValue.name;
    this.showLoggedInControls = Auth.isLoggedIn() && !Auth.isGuest();
  });

  this.logout = () => {
    Auth.logout();
    $location.path('/');
    // TODO: This is a solution to the multiple state issue
    // However, a better solution is to not rely on this, and manage state better
    // Most likely through redux-like architecture
    // $window.location.reload();
  };
}

angular.module('sq.navbar.profile.component', [])
.component('navbarProfile', {
  controller: NavbarProfilerController,
  templateUrl: 'navbar/profile/navbar.profile.tpl.html',
});
